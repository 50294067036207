// Local libs
import { waitTillAvailable } from "@/libs/utils/Functions";
const bodyPix = require("@tensorflow-models/body-pix");

function getCenterPosition(canvas, img) {
  const scale = Math.max(canvas.width / img.width, canvas.height / img.height);
  const w = img.width * scale;
  const h = img.height * scale;
  const l = canvas.width / 2 - w / 2;
  const t = canvas.height / 2 - h / 2;
  return { l, t, w, h };
}

async function drawVirtualBackground(bodyPixNet, context, video, canvas, img, videoCanvas, videoContext, imageCanvas, imageContext, logoImg) {
  // Virtual Background without blur
  if (video.readyState != 4) return;

  const { l, t, w, h } = getCenterPosition(canvas, img);
  imageContext.drawImage(img, l, t, w, h);
  const imageData = imageContext.getImageData(0, 0, imageCanvas.width, imageCanvas.height);

  videoContext.clearRect(0, 0, videoCanvas.width, videoCanvas.height);
  const left = (videoCanvas.width - video.width) / 2;
  const top = (videoCanvas.height - video.height) / 2;
  videoContext.drawImage(video, left, top, video.width, video.height);

  const videoData = videoContext.getImageData(0, 0, videoCanvas.width, videoCanvas.height);
  const segmentation = await bodyPixNet.segmentPerson(videoCanvas);
  const maskData = bodyPix.toMask(segmentation);
  const leadingPixels = top * videoCanvas.width * 4;
  for (let i = 0; i < maskData.data.length; i += 4) {
    if (maskData.data[i + 3] == 0) {
      imageData.data[leadingPixels + i] = videoData.data[i];
      imageData.data[leadingPixels + i + 1] = videoData.data[i + 1];
      imageData.data[leadingPixels + i + 2] = videoData.data[i + 2];
      imageData.data[leadingPixels + i + 3] = videoData.data[i + 3];
    }
  }
  context.putImageData(imageData, 0, 0);
}

export default {
  loadBodyPix: async ({ commit }) => {
    const bodyPixNet = await bodyPix.load();
    commit("setState", { bodyPixNet });
  },
  stopCanvasVideo: async ({ commit, state }) => {
    const { vBgAnimationId } = state;
    if (vBgAnimationId) {
      cancelAnimationFrame(vBgAnimationId);
    }
    commit("setState", { vBgAnimationId: null, vBgRendering: false });
  },
  startCanvasVideo: async ({ commit, state, dispatch }, { video, canvas }) => {
    const img = new Image();
    const logoImg = new Image();
    logoImg.src = "/logo.png";

    await dispatch("stopCanvasVideo");
    const bodyPixNet = await waitTillAvailable(() => state.bodyPixNet, 100);
    commit("setState", { vBgRendering: true });
    const imageCanvas = document.createElement("canvas");
    const videoCanvas = document.createElement("canvas");
    const imageContext = imageCanvas.getContext("2d");
    const videoContext = videoCanvas.getContext("2d");
    const context = canvas.getContext("2d");
    const smokeType = state.smokeType;
    if (smokeType == "picture") img.src = `/small_backgrounds/${state.pictureType}`;
    img.onload = function () {
      async function updateCanvas() {
        video.height = canvas.height;
        video.width = (video.height * video.videoWidth) / video.videoHeight;
        imageCanvas.width = videoCanvas.width = canvas.width;
        imageCanvas.height = videoCanvas.height = canvas.height;

        const vBgRendering = state.vBgRendering;
        if (smokeType == "picture") {
          await drawVirtualBackground(bodyPixNet, context, video, canvas, img, videoCanvas, videoContext, imageCanvas, imageContext, logoImg);
        } else if (video.readyState == 4) {
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
        }
        canvas.style.opacity = 1;
        if (vBgRendering) {
          const vBgAnimationId = requestAnimationFrame(updateCanvas);
          commit("setState", { vBgAnimationId });
        }
      }

      const vBgAnimationId = requestAnimationFrame(updateCanvas);
      commit("setState", { vBgAnimationId });
    };
  },
  captureImage: async ({ commit, state }, { video, canvas }) => {
    const img = new Image();
    const logoImg = new Image();
    let logoLoaded = false;
    logoImg.onload = () => {
      logoLoaded = true;
    };
    logoImg.src = "/logo.png";

    const bodyPixNet = await waitTillAvailable(() => state.bodyPixNet, 100);
    commit("setState", { vBgRendering: true });
    const imageCanvas = document.createElement("canvas");
    const videoCanvas = document.createElement("canvas");
    const imageContext = imageCanvas.getContext("2d");
    const videoContext = videoCanvas.getContext("2d");
    const context = canvas.getContext("2d");

    const smokeType = state.smokeType;
    if (smokeType == "picture") img.src = `/backgrounds/${state.pictureType}`;
    img.onload = function () {
      async function updateCanvas() {
        video.height = canvas.height;
        video.width = (video.height * video.videoWidth) / video.videoHeight;
        imageCanvas.width = videoCanvas.width = canvas.width;
        imageCanvas.height = videoCanvas.height = canvas.height;

        if (smokeType == "picture") {
          await waitTillAvailable(() => logoLoaded, 33);
          await drawVirtualBackground(bodyPixNet, context, video, canvas, img, videoCanvas, videoContext, imageCanvas, imageContext, logoImg);
        } else if (video.readyState == 4) {
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
        }
        const image = canvas.toDataURL("image/png");
        commit("setState", { image });
      }
      requestAnimationFrame(updateCanvas);
    };
  },
};
