import AVManager from "@/libs/WebAV/AVManager";
import { state, getters, mutations } from "./state";

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions: {
    setup: async ({ commit, state }) => {
      const avManager = new AVManager({
        aspectRatio: state.aspectRatio,
        facingMode: "environment",
      });
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      commit("setState", {
        avManager,
        isMobile,
      });
    },
    ...require("./media").default,
    ...require("./virtual_background").default,
  },
};
