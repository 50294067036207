// Libraries
import { waitTillAvailable } from "@/libs/utils/Functions";

// Utilities
import { mapGetters } from "vuex";

// Constants

export default function () {
  return {
    data() {
      return {
        capturing: false,
      };
    },
    computed: {
      ...mapGetters(["videoStream", "stream", "image", "aspectRatio", "blurred", "isMobile"]),
    },
    watch: {
      videoStream(stream) {
        this.play(stream);
      },
    },
    mounted() {
      const video = this.$refs.video;
      video && (video.onloadeddata = () => this.onVideoDataLoaded());
      this.play(this.videoStream);
    },
    beforeUnmount() {
      const video = this.$refs.video;
      video && (video.onloadeddata = null);
    },
    methods: {
      async onVideoDataLoaded() {
        const video = this.$refs.video;
        const canvas = this.$refs.canvas;
        const cameraView = this.$refs.camera;
        if (cameraView && video && canvas) {
          const shouldRotate = video.videoWidth > video.videoHeight;
          const width = shouldRotate ? video.videoHeight : video.videoWidth;
          const height = shouldRotate ? video.videoWidth : video.videoHeight;
          canvas.width = cameraView.clientWidth;
          canvas.height = (canvas.width * height) / width;
          await this.$store.dispatch("startCanvasVideo", { video, canvas });
          const stream = canvas.captureStream();
          if (stream) {
            this.$store.commit("setState", { stream });
          }
        }
      },
      async captureImage() {
        if (!this.stream) return;
        this.$store.dispatch("stopCanvasVideo");

        const video = this.$refs.video;
        const canvas = document.createElement("canvas");

        if (video && canvas) {
          video.pause();

          const shouldRotate = video.videoWidth > video.videoHeight;
          const width = shouldRotate ? video.videoHeight : video.videoWidth;
          const height = shouldRotate ? video.videoWidth : video.videoHeight;
          canvas.width = Math.min(width, height, 1200);
          canvas.height = (canvas.width * height) / width;
          this.capturing = true;
          await this.$store.dispatch("captureImage", { video, canvas });
          await waitTillAvailable(() => this.image, 500);
          this.capturing = false;
          this.videoStream && this.videoStream.getTracks().forEach((track) => track.stop());
          this.$store.commit("setState", { videoStream: null, stream: null });
        }
      },
      play(stream) {
        if (!this.$refs.video) return;
        if (stream && this.$refs.video.srcObject == stream) return;

        this.$refs.video.srcObject = stream;
        if (!stream) return;
        waitTillAvailable(() => this.$refs.video.readyState == 4, 100).then(() => {
          this.$refs.video.play();
        });
      },
    },
  };
}
