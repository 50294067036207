export const state = {
  isMobile: false,
  videoStream: null,
  stream: null,
  image: null,
  aspectRatio: 3 / 4,
  blurred: false,
  bodyPixNet: null,
  vBgRendering: false,
  vBgAnimationId: null,
  smokeType: "picture", // smoke, picture, cover
  pictureType: "bg1.jpg",
};

export const getters = {
  isMobile: (state) => state.isMobile,
  videoStream: (state) => state.videoStream,
  stream: (state) => state.stream,
  image: (state) => state.image,
  aspectRatio: (state) => state.aspectRatio,
  blurred: (state) => state.blurred,
  bodyPixNet: (state) => state.bodyPixNet,
  vBgRendering: (state) => state.vBgRendering,
  vBgAnimationId: (state) => state.vBgAnimationId,
  smokeType: (state) => state.smokeType,
  pictureType: (state) => state.pictureType,
};
export const mutations = {
  setState: (state, payload) => {
    Object.entries(payload).forEach(([key, value]) => {
      state[key] = value;
    });
  },
};
