<template>
  <div>
    <div class="hero">
      <div class="hero__bg"><img src="../assets/../assets/img/hero_01.jpg" alt="ディルーヤ" /></div>
      <div class="hero__logo"><img src="../assets/img/logo_opacity_01.png" alt="サウジアラビア政府観光局 ロゴ" /></div>
    </div>
    <div class="intro">
      <div class="intro__brush"><img src="../assets/img/brush_blue_01.png" alt="ブラシテクスチャ" /></div>
      <div class="intro__block">
        <div class="wrap">
          <h1>サウジアラビアの<br class="spsml-only" />フォトジェニックな背景で、<br />バーチャル記念撮影しませんか？</h1>
          <p>ハッシュタグ<span>#サウジに行こう</span>で<br />SNSに投稿していただいた方の中から<br />抽選で23名様に賞品プレゼント！</p>
        </div>
      </div>
    </div>
    <div class="thumb">
      <div class="wrap">
        <img src="../assets/img/icon_01.png" alt="ハートのアイコン" class="icon" />
        <h2>まずは好きな背景を選びましょう</h2>
        <div class="thumb__block">
          <div v-for="(item, index) in items" :key="index" class="thumb__block__card" @click="setBackground('picture', item.image_name)">
            <input :id="item.inputId" type="radio" name="rate" :value="item.inputId" />
            <label :for="item.inputId">
              <div :id="item.imgId" class="contents">
                <img :src="`/thumbs/${item.image_name}`" :alt="item.description" />
                <p>
                  <template v-for="(text, idx) in item.description.split('\n')">{{ text }}<br :key="idx" /></template>
                </p>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="heading">
      <div class="wrap">
        <img src="../assets/img/icon_02.png" alt="カメラのアイコン" class="icon" />
        <h2>自分と背景の位置を調整して<br />撮影ボタンを押してください</h2>
      </div>
    </div>
    <div class="camera">
      <div class="wrap">
        <div v-show="!image" class="camera__block">
          <div ref="camera" style="width: 100%; background: #ddd">
            <div
              v-show="!stream"
              style="
                width: 100%;
                aspect-ratio: 3 / 4;
                background: #ddd;
                color: #000;
                font-size: 26px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
              ">
              <span style="text-align: center; line-height: 3rem">背景画像を選択してください</span>
            </div>
            <div v-show="stream" style="display: flex; position: relative">
              <video ref="video" playsinline muted style="display: none"></video>
              <embed src="/loading.svg" width="64px" height="64px" style="position: absolute; top: 50%; left: 50%; translate: -50% -50%; z-index: 1" />
              <canvas ref="canvas" style="opacity: 0; z-index: 10" />
            </div>
          </div>
        </div>
        <div v-if="image" class="camera__block">
          <div style="width: 100%">
            <img v-if="image" :src="image" alt="Captured Image" style="width: 100%" />
          </div>
        </div>
        <div class="camera__btn">
          <div id="shooting-btn" class="btn" style="user-select: none" @click.prevent="captureImage">
            <img v-if="!capturing" src="../assets/img/icon_02.png" alt="カメラのアイコン" />
            <embed v-else src="/loading.svg" />
            撮影する
          </div>
        </div>
        <div class="camera__txt">
          <p>撮影ボタンを押したら、撮影完了です！<br />撮影した写真は長押しで保存できます</p>
          <p class="note">※撮影した写真はサーバーには保存されません。</p>
        </div>
      </div>
    </div>
    <div class="strong-txt">
      <p>撮影した写真を<br />ハッシュタグ #サウジに行こう で<br />SNSに投稿していただいた方の中から<br />抽選で23名様に賞品プレゼント！</p>
    </div>
    <footer class="footer">
      <div class="footer__logo">
        <img src="../assets/img/logo_blue_01.png" alt="サウジアラビア政府観光局" />
      </div>

      <p class="footer__copyright">Website copyright &copy; 2022 WHITE Inc. All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
import BodyPixMixin from "./mixins/BodyPixMixin";

const dumpData = [
  {
    inputId: "a",
    imgId: "Red-Sand-Dune",
    image_name: "bg_01.jpg",
    description: "赤い砂漠",
  },
  {
    inputId: "b",
    imgId: "AlUla",
    image_name: "bg_02.jpg",
    description: "世界遺産\nヘグラ遺跡（アルウラ）",
  },
  {
    inputId: "c",
    imgId: "Diriyah",
    image_name: "bg_03.jpg",
    description: "サウジアラビア発祥の地\nディルイーヤ",
  },
  {
    inputId: "d",
    imgId: "Riyadh",
    image_name: "bg_04.jpg",
    description: "キングダムセンターと\nリヤドの夜景",
  },
  {
    inputId: "e",
    imgId: "Red-Sea",
    image_name: "bg_05.jpg",
    description: "紅海",
  },
  {
    inputId: "f",
    imgId: "Al-Qarah-Mountain",
    image_name: "bg_06.jpg",
    description: "アル・カラ山の洞窟",
  },
];

export default {
  mixins: [BodyPixMixin()],
  data() {
    return { items: dumpData };
  },
  mounted() {
    this.$store.dispatch("setup");
    this.$store.dispatch("loadBodyPix");
  },
  methods: {
    async setBackground(smokeType, pictureType = "") {
      this.$store.commit("setState", { smokeType, pictureType, image: null });
      this.$store.dispatch("setupAVManager");
      this.$refs.camera.scrollIntoView({ behavior: "smooth" });
      if (smokeType == "picture") fetch(`/backgrounds/${pictureType}`);
    },
    start() {
      this.$store.dispatch("setupAVManager");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import "../assets/css/reset.css";
@import "../assets/css/common.css";
</style>
<style scoped></style>
