window.AudioContext = window.AudioContext || window.webkitAudioContext;
navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

export default class AVManager {
  constructor(opts) {
    this.stream = null;

    this.aspectRatio = 4 / 3;
    this.facingMode = "user";
    this._context = null;
    Object.assign(this, opts || {});
  }

  on(event, callback) {
    this._events = this._events || {};
    this._events[event] = callback;
  }

  emit(...args) {
    this._events = this._events || {};
    if (args.length > 0) {
      const event = args.shift();
      const callback = this._events[event];
      if (callback) callback(...args);
    }
  }

  get context() {
    if (!this._context) this._context = new AudioContext();
    return this._context;
  }

  setVolume(value) {
    this.volume = value;
  }

  async requestAVPermission(opts) {
    opts = Object.assign({ audio: false, video: true }, opts || {});
    this.stream = await navigator.mediaDevices.getUserMedia(opts);
  }

  async requestAVStream(opts) {
    const defaultOpts = {
      audio: false,
      video: {
        height: { ideal: 960 },
        aspectRatio: { exact: this.aspectRatio },
        facingMode: { exact: this.facingMode },
      },
    };

    const constraints = Object.assign(defaultOpts, opts || {});
    // Stop the current stream
    if (this.stream) this.stream.getTracks().forEach((track) => track.stop());

    try {
      this.stream = await navigator.mediaDevices.getUserMedia(constraints);
    } catch (ex) {
      try {
        constraints.video.aspectRatio = { ideal: this.aspectRatio };
        this.stream = await navigator.mediaDevices.getUserMedia(constraints);
      } catch (ex) {
        constraints.video.facingMode = { ideal: this.facingMode };
        this.stream = await navigator.mediaDevices.getUserMedia(constraints);
      }
    }

    return this.stream;
  }
}
