import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import bodypix from "./components/bodypix";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    bodypix,
  },
});

new Vue({
  el: "#app",
  components: {
    App,
  },
  store: store,
  render: (h) => <App />,
});
