const DEFAULT_DELAY = 33;

export function waitTillAvailable(getFunc, delay = DEFAULT_DELAY) {
  return new Promise(function (resolve) {
    const interval = setInterval(() => {
      const value = getFunc();
      if (value) {
        clearInterval(interval);
        resolve(value);
      }
    }, delay);
  });
}
