export default {
  setupAVManager: async ({ commit, state }) => {
    const AVM = state.avManager;
    AVM.aspectRatio = state.aspectRatio;
    await AVM.requestAVPermission();

    let retries = 0;
    async function requestAVStream(resolve, reject) {
      if (retries > 10) {
        reject();
        return;
      }
      retries++;
      try {
        resolve(await AVM.requestAVStream());
      } catch (ex) {
        setTimeout(requestAVStream, 100);
      }
    }
    const stream = await new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          requestAVStream(resolve, reject);
        } catch (ex) {
          setTimeout(requestAVStream.bind(null, resolve, reject), 100);
        }
      }, 100);
    });
    if (stream) {
      commit("setState", { videoStream: stream, stream: stream });
    }
  },
};
